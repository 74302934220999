import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import Breadcrumbs from "components/Breadcrumbs"
import PromotionsContent from "page_components/promotions/PromotionsContent"

const Specials = ({ data }) => {
  const breadcrumbs_data = [
    {
      name: "Promocje",
      href: "/promocje/",
    },
  ]

  return (
    <Layout
      seo={{
        title: "Promocje",
        description:
          "Oferta promocyjna. Umów się na wizytę Centrum Medyczne Medica per Pedes Nowy Targ.",
      }}
    >
      <Breadcrumbs breadcrumbs_data={breadcrumbs_data} />
      <PageHeader header="Promocje" />
      <PromotionsContent promotions={data?.allWpPromotion?.nodes} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPromotion(sort: { fields: date, order: DESC }) {
      nodes {
        id
        acfPromotions {
          desc
          endDate
          fieldGroupName
          startDate
          title
          image {
            altText
            sourceUrl
          }
        }
      }
    }
  }
`

export default Specials
