import "./styles.scss"

import React from "react"
import { Link } from "gatsby"

import formatDate from "utils/formatDate"

const SinglePromotion = ({ promotion }) => {
  const { desc, endDate, startDate, title, image } = promotion

  return (
    <div className="single-promotion">
      <div className="single-promotion__top">
        <div className="single-promotion__img">
          <img src={image?.sourceUrl} alt={image?.altText} />
        </div>
        <div className="single-promotion__content">
          <p className="single-promotion__title">{title}</p>
          <div className="single-promotion__dates">
            <div className="single-promotion__date">
              <img src={require("assets/icons/calendar.svg").default} alt="" />
              <span>Data startu: </span>
              <br className="d-sm-none" />
              <span>{formatDate(startDate)}</span>
            </div>
            <div className="single-promotion__date">
              <img src={require("assets/icons/calendar.svg").default} alt="" />
              <span>Data zakończenia: </span>
              <span>{formatDate(endDate)}</span>
            </div>
          </div>
          <p className="single-promotion__text">{desc}</p>
        </div>
      </div>
      <div className="single-promotion__bottom">
        <p>Masz pytania odnośnie promocji lub chcesz umówić wyzytę?</p>
        <div className="single-promotion__contact">
          <div className="single-promotion__phone">
            <img src={require("assets/icons/phone.svg").default} alt="" />
            <a href="tel:0048500301420">+48 500 301 420</a>
          </div>
          <p>lub</p>
          <Link to="/zespol/">Umów wizytę online</Link>
        </div>
      </div>
    </div>
  )
}

export default SinglePromotion
