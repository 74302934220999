import "./styles.scss"

import React from "react"

import SinglePromotion from "./components/SinglePromotion"

const PromotionsContent = ({ promotions }) => {
  return (
    <div className="promotions-content">
      <div className="container">
        {promotions.map(promotion => (
          <SinglePromotion
            key={promotion.id}
            promotion={promotion?.acfPromotions}
          />
        ))}
      </div>
    </div>
  )
}

export default PromotionsContent
